import React, {Component} from 'react';

class Gif extends Component {
    // When video has loaded, add className loaded
    constructor(props) {
        super(props)
        this.state = {
            // Set loaded state to false by default
            loaded: false
        }
    }

    render() {
        const {loaded} = this.state
        const {images} = this.props
        return (
            <video 
                //  When the loaded state is true, add a loaded class
                className={`grid-item video ${loaded ? 'loaded' : ''}`}
                autoPlay
                loop
                src={images.original.mp4}
                onLoadedData={() => this.setState({loaded: true})}
            />
        )
    }
}

export default Gif