import React, {Component} from 'react';
// import loader spinner as image
import loader from './images/loader.svg';
import clearButton from './images/close-icon.svg';
import {ReactComponent as Logo} from './images/n8-bool.svg';
import Gif from './Gif'

const randomChoice = arr => {
  const randIndex = Math.floor(Math.random() * arr.length)
  return arr[randIndex]
}

// We pick out our props inside the Header component
// We can pass down functions as props as well as thigns like
// numbers, strings, arrays, or objects
const Header = ({clearSearch, hasResults}) => (
  <div className='header grid'>
    {/* If we have results, show the clear button */}
    {hasResults ? 
      <button onClick={clearSearch}>
        <img src={clearButton}/>
      </button> 
      : <h1 className='title'>N8's React GIF Search</h1>
    }
  </div>
)

const UserHint = ({loading, hintText}) => (
  <div className='user-hint'>
    {/* here we check whether we have a loading state and render out
    either our spinner or hintText based on that, using a ternary operator */}
    {loading ? <img className='block mx-auto' src={loader} /> : hintText}
  </div>
)

const Footer = () => (
  <footer>
    <a href="https://nathanmandreza.com"><Logo /></a>
  </footer>
)

class App extends Component {

  constructor(props) {
    super(props)
    this.state = {
      // set loading spinner off until search is initiated
      loading: false,
      // store search term inside of our state
      searchTerm:'',
      hintText: '',
      gifs: []
    }
  }

  // We want a function that searches the Giphy API using fetch,
  // puts the search term in the query URL, then do something w/ the results

  // We can write async methods into our components that let us use the async/await functions
  searchGiphy = async searchTerm => {
    // Turn on loading spinner
    this.setState({
      loading: true
    })
    // first we try our fetch
    try {
      // here we await for our response to come back
      const response = await fetch(
        `https://api.giphy.com/v1/gifs/search?api_key=cZkfnxNz3IYNuSejYrXFgi72pEqJy0Yj&q=${searchTerm}&limit=25&offset=0&rating=PG&lang=en`
      )
      // convert raw response to json data
      // const {data} gets the .data part of our response
      const {data} = await response.json()

      // Check here if the array of results is empty, if it is, 
      // throw an error which will stop the code here and handle in catch area

      if (!data.length) {
        throw `Nothing found for ${searchTerm}`
      }

      // grab a random result from our images
      const randomGif = randomChoice(data)

      this.setState((prevState, props) => ({
        ...prevState,
        // Use the spread operator to take previous gifs and spread them out,
        // then add our new gif onto the end
        gifs: [...prevState.gifs, randomGif],
        // turn off loading spinner again
        loading: false,
        hintText: `Hit enter to see more ${searchTerm}`
      }))
    // if our fetch fails, we catch it here
    } catch (error) {
      this.setState((prevState, props) => ({
        hintText: error,
        loading: false
      }))
      console.log(error)
    }
  }

  // With create-react-app we can write our methods as arrow functions
  // there's no need for constructor and bind
  handleChange = event => {
    // the below is same as saying: const value = event.target.value
    const {value} = event.target
    // By setting the searchTerm in our state
    // and also using that on the input as the value prop,
    // we have created a controlled input.
    this.setState((prevState, props) => ({
      // we take our old props and spread them out here
      ...prevState,
      // and then overwrite the ones we want from the input below after 
      searchTerm: value,
      // set hint text only when there's > 2 characters in the input
      hintText: value.length > 2 ? `Hit enter to search ${value}` : ''
    }))
  }

  handleKeyPress = event => {
    const {value} = event.target
    // when we have 2+ characters in our search box
    // and we have also pressed enter, we want to run a search
    if (value.length > 2 && event.key === 'Enter') {
      // Here we call our searchGiphy function using the search term
      this.searchGiphy(value)
    }
  }  

  // Here we reset our state by clearing everything out
  // and returning it to the default state
  clearSearch = () => {
    this.setState((prevState, props) => ({
      ...prevState,
      searchTerm: '',
      hintText: '',
      gifs:[]
    }))
    // Grab the input and return focus to it 
    this.textInput.focus()
  }

  render() {
    // const {searchTerm} = this.state is the same as const searchTerm = this.state.searchTerm
    const {searchTerm, gifs} = this.state
    // Set a variable to see if we have any gifs by checking length of array
    const hasResults = gifs.length
    return (
      <div className="page">
        {/* We can create an element to run clearSearch */}
        {/* <h1 onClick={this.clearSearch}>Clear search</h1> */}
        {/* OR, we can pass down the clearSearch method/function from the App class component to the Header component as a prop */}
        <Header clearSearch={this.clearSearch} hasResults={hasResults} />

        <div className='search grid'>
          {/* our stack of GIF images */}
          {/* v1: Only render video when we have a gif in the state; 
          we can test for it w/ && */}
          {/* {gif &&
            <video 
              className='grid-item video'
              autoPlay
              loop
              // grab gif from the state above so we don't have to repeat this.state here
              src={gif.images.original.mp4}
            />
          } */}

          {/* v2: Instead of rendering only 1 gif, 
          we loop over our array of gif images from our state 
          and create multiple videos from it */}
          {this.state.gifs.map(gif => (
            // spread out all our props onto our Gif component
            <Gif {...gif} />
          ))}

          <input 
            className='input grid-item' 
            placeholder='Type something' 
            onChange={this.handleChange}
            onKeyPress={this.handleKeyPress}
            value={searchTerm}
            ref={(input) => {
              this.textInput = input
            }}
          />
        </div>

        {/* here we pass our userHint all of our state using a spread */}
        <UserHint {...this.state} />
        <Footer />
      </div>
    );
  }
}

export default App;
